input[type="text"]:focus, input[type="password"]:focus,
textarea:focus, select:focus {
  font-size: 16px !important;
}
*{
  box-sizing: border-box;
}
.body, .html{
  height: 100vh;
  overflow: hidden;
}

.appViewport{
  height: 100vh;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;
}

.topBar{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  display: flex;
}


.stickyFilter{
  background-color: white;
  padding-bottom: 25px;
}

.remove-margin{
  margin: 0 !important;
  padding-top: 2px;
}
.cargando{
  width: 100%;
  text-align: center;
}

.fullWidth{
  width: 100%;
}
